.section {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.internships-table {
  width: 100%;
  display: grid;
  max-width: 1100px;
  grid-template-columns: repeat(4, 1fr);
  max-height: 712px;
  overflow-y: scroll;
  gap: 24px 20px;
  margin: 106px auto 0 auto;
  padding: 0 10px;

  @media screen and (max-width: 1200px) {
    & {
      grid-template-columns: repeat(3, 1fr);
      max-width: 1000px;
    }
  }
  @media screen and (max-width: 900px) {
    & {
      grid-template-columns: repeat(2, 1fr);
      max-width: 680px;
    }
  }
  @media screen and (max-width: 600px) {
    & {
      grid-template-columns: repeat(1, 1fr);
      max-width: 340px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    height: 220px;
    overflow: hidden;

    &-title {
      padding: 20px 15px;
      background-color: #eac131;
      color: #252a38;
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      white-space: nowrap;
    }
    &-description {
      background-color: #165aaa;

      flex: 1 1 0%;
      max-height: 156px;
      overflow: hidden;

      p {
        margin: 15px 0;
        font-size: 14px;
        padding: 0 25px;
        max-height: 120px;
        overflow: scroll;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #fff;
      }
    }
  }
}

.internships-table__wrapper {
  padding: 0 25px;

  @media screen and (max-width: 425px) {
    & {
      padding: 0 15px;
    }
  }
}

.internships-table__title-wrapper {
  width: 100%;
  padding: 0 25px;
  text-align: center;

  h1 {
    font-size: 56px;
    color: #165aaa;
    line-height: 68px;
    font-weight: 300;
  }

  @media screen and (max-width: 425px) {
    & {
      h1 {
        font-size: 48px;
        line-height: 56px;
      }
    }
  }
}
