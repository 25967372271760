@import url("https://fonts.googleapis.com/css2?family=Inter+Tight:wght@400;500;700&display=swap");

.partners_title {
  font-family: "Inter Tight";
  font-style: normal;
  font-weight: 300;
  font-size: 56px;
  line-height: 68px;

  color: #165aaa;
  padding: 0 25px;

  margin: 0 0 80px 0;
  text-align: center;

  @media screen and (min-width: 768px) {
    font-size: 4rem;
  }
}

.parntner-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 52px;
  grid-row-gap: 32px;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;

  .parntner-item {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    text-align: center;
    align-items: center;
  }

  .partner-item-img {
    width: 120px;
    height: 120px;
    padding-bottom: 16px;
    justify-self: center;
    margin-left: 15px;
  }

  .partner-item-text {
    font-family: "Inter Tight", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #252a38;
  }
}

@media only screen and (max-width: 600px) {
  .parntner-container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(10, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    width: 100%;
    margin: 0;
  }

  .partner-item {
    display: flex;
    flex-direction: column;
    margin: 0;
    text-align: center;
    align-items: center;
  }

  .partner-item-img {
    width: 80px;
    height: 80px;
    padding-bottom: 8px;
  }

  .partner-item-text {
    font-size: 10px;
    line-height: 14px;
  }
}
