.publishers-table {
  padding: 0px 25px;
  display: grid;
  gap: 60px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

@media screen and (max-width: 724px) {
  .publishers-table {
    padding: 0 10px;
  }
}

@media screen and (max-width: 1090px) {
  .publishers-table {
    max-width: 738px;
    margin-left: auto;
    margin-right: auto;
  }
}
