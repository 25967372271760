@import url("https://fonts.googleapis.com/css2?family=Inter+Tight:wght@300;400;500;600&family=Nunito:wght@300;400;500;600;700;800;900&display=swap");

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@mixin font-sett {
  font-family: "Inter Tight";
  text-align: center;
  font-style: normal;
}

$title-color: #eac131;

.container {
  @include flex-center;

  width: 100%;
  height: fit-content;
  background-color: #252a38;

  header {
    @include flex-center;
    padding: 0 5%;

    h1 {
      @include font-sett;

      color: $title-color;
      font-size: 48px;
      font-weight: 500;
      max-width: 856px;
      margin-top: 10%;
      margin-bottom: 4%;
      line-height: 58px;
    }

    section {
      @include font-sett;

      max-width: 1024px;
      color: #ffffff;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
    }
  }

  .main_block {
    @include flex-center;
    margin: 10% 10% 20% 10%;

    .title {
      @include flex-center;

      h1 {
        @include font-sett;

        font-weight: 300;
        font-size: 56px;
        line-height: 68px;
        color: $title-color;
        max-width: 362px;
      }

      p {
        @include font-sett;

        max-width: 218px;
        font-weight: 400;
        font-size: 28px;
        line-height: 35px;
        color: #e0e0e0;
        margin-top: 8px;
      }
    }

    .section_block {
      display: flex;
      margin-top: 6%;
      font-family: "Inter Tight";

      h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        color: $title-color;
        line-height: 32px;
        letter-spacing: 0.02em;
      }

      p {
        color: #ffffff;
        letter-spacing: 0.02em;
      }

      .left_content {
        width: 50%;
        margin-right: 8%;
        text-align: right;

        :first-child {
          h2 {
            margin-bottom: 20px;
          }
          p {
            font-weight: 500;
            font-size: 20px;
            line-height: 38px;
            margin: 0;
          }
        }

        :last-child {
          margin-top: 15%;

          h2 {
            margin-bottom: 17px;
          }
          p {
            margin: 0;
            margin-bottom: 4%;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
          }
        }
      }

      .right_content {
        width: 50%;
        text-align: left;

        :first-child {
          h2 {
            max-width: 420px;
            margin-bottom: 20px;
          }
          ul {
            padding: 0;

            li {
              color: #ffffff;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.02em;
              margin-bottom: 4%;

              span {
                margin-left: 2%;
              }
            }
          }
        }

        :nth-child(2) {
          h2 {
            margin-top: 13%;
            margin-bottom: 16px;
          }
          p {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
          }
        }

        :last-child {
          h2 {
            margin-top: 8%;
            margin-bottom: 16px;
          }
          p {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
          }
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .section_block {
    flex-direction: column !important;
  }

  .left_content,
  .right_content {
    width: 100% !important;
    text-align: center !important;
  }

  .left_content {
    margin-right: 0 !important;
  }

  .right_content {
    ul {
      text-align: left !important;
    }
  }
}
