.form__container {
  max-width: 596px;
  width: 100%;
  background: #252a38;
  padding: 64px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 32px;
    color: #eac131;
    font-weight: 500;
    text-align: center;
  }
  h5 {
    font-weight: 400;
    color: #e0e0e0;
    margin-top: 40px;
  }

  input {
    width: 100%;
    color: #252a38;
    background: #fff;
    padding: 25.5px 24px;
    font-size: 24px;
    font-weight: 500;
    border-radius: 16px;
    margin-top: 24px;
  }

  button {
    width: 100%;
    color: #252a38;
    background: #eac131;
    padding: 25.5px 24px;
    font-size: 24px;
    font-weight: 600;
    border-radius: 16px;
    margin-top: 24px;
  }
}
