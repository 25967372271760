.footer {
  background: rgb(37, 42, 56);
  padding-top: 160px;

  .text-content {
    color: #fff;
    font-weight: 500;
    font-size: 20px;
  }

  .text-content__wrapper {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__top-wrapper {
    max-width: 950px;
    margin: 0 auto;
    padding: 0 40px;

    @media screen and (max-width: 425px) {
      & {
        padding: 0 25px;
      }
    }
  }

  &__top {
    display: flex;
    justify-content: center;
    gap: 40px;

    &-left {
      max-width: 569px;
      width: 100%;

      h1 {
        color: #eac131;
        font-size: 24px;
        font-weight: 700;
      }
    }
    &-right {
      h1 {
        color: #eac131;
        font-size: 24px;
        font-weight: 700;
      }
    }

    &-bottom {
      margin-top: 80px;
      text-align: center;

      h1 {
        font-weight: 700;
        font-size: 20px;
        color: #fff;
      }
      h3 {
        font-weight: 500;
        font-size: 20px;
        color: #fff;
        margin: 48px 0;
      }
    }

    @media screen and (max-width: 768px) {
      & {
        flex-direction: column;
      }
    }
  }

  &__bottom {
    max-width: 1200px;
    width: 100%;
    margin-inline: auto;

    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      color: #252a38;
      font-size: 16px;
    }

    @media screen and (max-width: 768px) {
      & {
        flex-direction: column;
        row-gap: 30px;
      }
    }
  }

  &__bottom-wrapper {
    padding: 64px 40px;
    background-color: #eac131;

    @media screen and (max-width: 425px) {
      & {
        padding: 64px 25px;
      }
    }
  }
}
