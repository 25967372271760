.about-block {
  text-align: left;
  font-weight: 500;
  color: #252a38d9;
  line-height: 24px;
  margin-top: 16px;
}

.about-block div {
  margin-top: 25px;
}
