.form-wrapper {
  max-width: 1112px;
  width: 100%;
  background: #eac131;
  border-radius: 24px;
  padding: 64px;
  margin: 58px auto 0 auto;
}

.form {
  display: flex;
  flex-direction: column;

  &-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    justify-content: center;
  }

  &__title {
    text-align: center;
    font-weight: 600;
    font-size: 36px;
    color: #165aaa;
  }

  &__subtitle {
    text-align: center;
    color: #252a38;
    font-size: 30px;
    font-weight: 700;
    margin-top: 10px;
  }

  &_btn-wrapper {
    margin-top: 48px;
    display: flex;
    justify-content: center;
  }

  &_btn {
    background: #165aaa;
    color: #fff;
    padding: 10px 25px;
    font-size: 18px;
    border-radius: 8px;
    font-weight: 500;
    transition: all 0.5s ease-in-out;

    &:hover {
      color: #eac131;
    }
  }

  &__description {
    text-align: center;
    margin-top: 64px;
    font-weight: 500;
    font-size: 24px;
    color: #252a38;
  }

  @media screen and (max-width: 425px) {
    & {
      &__title {
        font-size: 32px;
      }

      &__subtitle {
        font-size: 26px;
      }
    }
  }
}
