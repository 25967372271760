.footer-wrapper {
  background-color: #eac131;
}

.footer {
  max-width: 1200px;
  width: 95%;
  margin: 0 auto;
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
  gap: 30px;

  align-items: center;
  font-weight: 500;

  @media screen and (max-width: 1028px) {
    & {
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
    }
  }
}
