.section {
  width: 100%;
  padding: 120px 0 200px 0;
  background: #252a38;

  &__inner-content {
    background: #252a38;
    max-width: 1200px;
    margin: 0 auto;
  }
}
