/* Variables */

@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')

$red: #ED1E22
$darkblue: #252A38
$blue: #165AAA
$yellow: #EAC131
$white: #fff
$lite: rgba(224,224,224,.25)
// Fonts

$basic: 'Inter Tight', sans-serif

// animation
.au
  animation: Up 1.4s .4s
.ad
  animation: Down 1.4s .4s
.al
  animation: Left 1.7s .4s
.ar
  animation: Right 1.7s .4s

@keyframes Right
  from
    transform: translateX(60px)
    opacity: 0
  to
    transform: translateY(0)
    opacity: 1

@keyframes Left
  from
    transform: translateX(-60px)
    opacity: 0
  to
    transform: translateY(0)
    opacity: 1

@keyframes Up
  from
    transform: translateY(20px)
    opacity: 0
  to
    transform: translateY(0)
    opacity: 1

@keyframes Down
  from
    transform: translateY(-20px)
    opacity: 0
  to
    transform: translateY(0)
    opacity: 1

// Placeholder
=placeholder
  &::-webkit-input-placeholder
    @content
  &:-moz-placeholder
    @content
  &::-moz-placeholder
    @content
  &:-ms-input-placeholder
    @content
