.block-title__wrapper {
  width: 100%;
  text-align: center;
  padding: 0 25px;
  margin-top: 120px;

  h1 {
    font-weight: 300;
    font-size: 56px;
    color: #252a38;
    margin-top: 8px;
  }

  h4 {
    font-weight: 500;
    font-size: 20px;
    color: #165aaa;
    margin-top: 8px;
  }
  @media screen and (max-width: 425px) {
    & {
      padding: 0 15px;
      h1 {
        font-size: 48px;
      }
    }
  }
}

.issuitable-accordion__wrapper {
  @media screen and (max-width: 425px) {
    & {
      padding: 0 15px;
    }
  }
  padding: 0 25px;
  margin-top: 64px;
  width: 100%;
}
