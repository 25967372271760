.accordion-wrapper {
  width: 100%;
  height: 172px;
  border-radius: 24px;
  overflow: hidden;
  background-color: #204e84;
}

.accordion {
  display: flex;
  flex-shrink: 0;
  height: 100%;
  transition: all 0.5s ease-in-out;

  &__item {
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    padding: 20px 15px;
    display: flex;
    align-items: flex-start;
    gap: 25px;

    &-title__wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      svg {
        min-width: 44px;
        min-height: 44px;
      }
    }
    &-title {
      margin-top: 16px;
      font-weight: 600;
      font-size: 20px;
      color: #eac131;
      text-align: center;
    }

    p {
      color: #fff;
      font-weight: 500;
      font-size: 18px;
    }
  }
}

.slow-appear-animation {
  animation: slow-appear 0.8s ease-in-out;
}

@keyframes slow-appear {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blocks-wrapper {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.blocks__item {
  padding: 25px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;

  svg {
    min-width: 90px;
    min-height: 90px;
  }

  &:first-child {
    border-radius: 24px 24px 0 0;
  }
  &:last-child {
    border-radius: 0 0 24px 24px;
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;

    h1 {
      font-weight: 600;
      font-size: 20px;
      color: #eac131;
      text-align: center;
    }
    p {
      margin: 0 !important;
      color: #fff;
      font-weight: 500;
      font-size: 18px;
    }
  }
}
