.side-menu {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #252a38;
  transition: all 0.5s ease-in-out;
}

.navbar__wrapper {
  height: 100%;
  padding-top: 106px;
}

.navbar {
  margin: 0;
  padding: 0;
  margin-top: 17px;
  border-top: 2px solid #1f232e;

  &__item {
    padding: 24px 32px;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    border-bottom: 2px solid #1f232e;

    transition: all 0.25s ease-in-out;
    &:hover {
      color: #eac131;
    }
  }
}
