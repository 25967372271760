.form__container {
  max-width: 596px;
  width: 100%;
  background: #252a38;
  padding: 64px 64px 74px 64px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 32px;
    color: #eac131;
    font-weight: 500;
    text-align: center;
  }

  .rate {
    display: flex;
    margin-top: 40px;
    gap: 5px;
  }
}
