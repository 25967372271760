@import "../node_modules/bootstrap/scss/bootstrap.scss";

//Andriy Ilko

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

main {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
}

a {
  color: inherit;
}

.nowrap {
  white-space: nowrap;
}

button {
  user-select: none;
}

.main {
  display: flex;
  flex-direction: column;
}

.w-full {
  width: 100%;
}

.mx-auto {
  margin: 0 auto;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.cursor-pointer {
  cursor: pointer;
}

.justify-between {
  justify-content: space-between;
}
.justify-start {
  justify-content: start;
}

.justify-end {
  justify-content: end;
}

.items-center {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}

.active-dot {
  border: 1px solid var(--bs-blue);

  span {
    background-color: var(--bs-blue) !important;
  }
}

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

.text-justify {
  text-align: justify;
}

.card-how-work {
  max-width: 320px;
  height: 246px;
  border-radius: 16px;
}

.custom-scroll {
  /* width */
  background-color: transparent;
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #eac131;
    border-radius: 6px;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  /* Handle on hover */
}

.grid-feedback {
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
}

.feedbackCard {
  position: relative;
  mask-image: linear-gradient(180deg, #000 60%, transparent);
}

.grid-contests {
  display: grid;
  gap: 64px 30px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}

.grid-design {
  padding: 0 25px;
  display: grid;
  gap: 64px 30px;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
}

@media screen and (max-width: 1206px) {
  .grid-design {
    max-width: 800px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 820px) {
  .grid-design {
    max-width: 410px;
    margin: 0 auto;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}

.swiper-design {
  .swiper-button-next,
  .swiper-button-prev {
    color: #eac131;
  }
}

.contests__input {
  position: relative;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 10px;
    z-index: 100;
    width: 20px;
    height: 20px;
    background-color: red;
  }
}

.contest-registration__wrapper {
  padding: 64px;

  @media screen and (max-width: 460px) {
    & {
      padding: 64px 32px;
    }
  }
}

.popup-top__animation {
  vertical-align: top;
  animation: popup-top 0.25s ease-in-out;
}

.hover-yellow {
  transition: all 0.25s ease-in-out;
  &:hover {
    color: #eac131 !important;
  }
}

.user-select-none {
  user-select: none;
}

.disabled {
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    cursor: not-allowed;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
  }
}

@keyframes popup-top {
  from {
    opacity: 0;
    transform: translateY(-1000px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.scale-125-animation {
  animation-name: scale-125;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

@keyframes scale-125 {
  0% {
    transform: scale(1);
  }
  15% {
    transform: scale(1.15);
  }
  25% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.full-screen {
  min-width: 0px;
  position: static;

  &:hover {
    position: absolute;
    display: flex;
    justify-content: center;
    text-align: center;
    top: 0;
    left: 0;
    min-width: 100%;
  }
}

.blue-hover {
  transition: all 0.25s ease-in-out;
  background: #eac131;
  &:hover {
    color: #fff;
    background: #165aaa;
  }
}

.look-btn {
  transition: all 0.25s ease-in-out;
  background: #fff;
  color: #165aaa;

  &:hover {
    background: #165aaa;
    color: #fff;
  }
}

.hover-btn {
  transition: all 0.25s ease-in-out;
  color: #252a38;
  background-color: #eac131;

  &:hover {
    background: #165aaa;
    color: #fff;
  }
}

.light-blue-hover {
  background: #fff;
  transition: all 0.5s ease-in-out;
  &:hover {
    background: rgba(177, 195, 255, 0.25);
  }
}

.slow-appearence {
  animation: opacity-1 1s ease-in-out;
}

@keyframes opacity-1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 1024px) {
  .header-content {
    flex-direction: column;
    align-items: center;
    row-gap: 40px;

    &__right {
      justify-content: center;
      min-width: 0;
      img {
        max-width: 500px;
      }
    }
  }

  .internships-table {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 880px) {
  .internships-table {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 630px) {
  .internships-table {
    grid-template-columns: repeat(1, 1fr);
  }
}

.achiements-block {
  justify-content: space-between;

  @media screen and (max-width: 1100px) {
    & {
      justify-content: center;
    }
  }
}

.paymentInfo-listItem {
  flex-direction: row;
  @media screen and (max-width: 880px) {
    & {
      flex-direction: column;
    }
  }
}

.bankInfo-wrapper {
  overflow-y: hidden;

  @media screen and (max-width: 665px) {
    & {
      overflow-x: scroll;
    }
  }
}
