.accordion-wrapper {
  max-width: 1200px;
  border: 3px solid #165aaa;
  margin: 0 auto;
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
}

.accordion {
  display: flex;
  flex-shrink: 0;
  color: black;
  transition: all 0.5s ease-in-out;

  &__item {
    transition: min-width 0.5s ease-in-out;
    cursor: pointer;
    padding: 16px 24px;
    display: flex;
    align-items: center;
    gap: 24px;
    overflow: hidden;

    &-title__wrapper {
      display: flex;
      flex-direction: column;

      .linkedin-url {
        margin-top: 10px;

        display: flex;
        align-items: center;
        gap: 5px;

        &__text {
          font-weight: 600;
          color: rgb(10, 102, 194);
        }
        &__icon {
          fill: rgb(10, 102, 194);
        }
      }

      img {
        max-width: 104px;
        height: 104px;
        border-radius: 50%;
      }

      h1 {
        font-weight: 600;
        font-size: 18px;
        color: #165aaa;
        white-space: nowrap;
        margin-top: 10px;
      }
    }

    &-text {
      font-weight: 500;
      font-size: 16px;
      color: #252a38;
      height: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
    }
  }
}

.blocks-wrapper {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;

  border: 3px solid #165aaa;
  border-radius: 16px;
}

.blocks__item {
  padding: 25px 30px;
  border-bottom: 3px solid #165aaa;

  &:last-child {
    border-bottom: none;
  }

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 125px;
    border-radius: 50%;
  }

  &-content {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 20px;
    text-align: center;

    h1 {
      font-weight: 600;
      font-size: 18px;
      color: #165aaa;
      white-space: nowrap;
    }
    .linkedin-url {
      &__text {
        font-size: 18px;
        font-weight: 600;
        color: rgb(10, 102, 194);
      }
      &__icon {
        fill: rgb(10, 102, 194);
      }
    }

    p {
      font-weight: 500;
      font-size: 16px;
      color: #252a38;
      height: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      margin: 0;
    }
  }
}
