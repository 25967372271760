.contests-section {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 120px 0 0 0;
}

.contests-section__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
}

.contests-section__title h1 {
  font-size: 56px;
  font-weight: 300;
  line-height: 68px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #165aaa;
}

.contests-section__title h3 {
  font-size: 32px;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: center;
  color: #eac131;
  margin-top: 8px;
}

.contests-section__content {
  display: flex;
  flex-direction: column;

  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  margin-top: 80px;
}

.contests-filter__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  padding: 0 25px;
}

.contests-filter__input-icon {
  position: absolute;
  left: 16px;
  top: 7px;
}

.contests-filter__input {
  padding: 8px 15px 7px 43px;
  border: 1px solid #b1c3ff;
  border-radius: 8px;
}

.contests-filter__btns {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.contests-filter__btns button {
  transition: all 0.5s ease-in-out;
  padding: 10px 25px;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
}

.contests-filter__btns button span {
  font-weight: 400;
  color: #78797b;
}

.contests-table {
  margin-top: 32px;
  display: grid;
  gap: 64px 30px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  padding: 0 25px;
}

.constests-table__card {
  display: flex;
  flex-direction: column;
  max-width: 360px;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: #fff;
  color: #252a38;
}

.constests-table__card-content {
  padding: 16px;
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.constests-table__card-title {
  font-weight: 700;
  font-size: 28px;
  text-align: left;
}

@media screen and (max-width: 1030px) {
  .contests-filter__wrapper,
  .contests-table {
    max-width: 750px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
