// Title

.title
  position: relative
  width: 60%
  margin-left: auto
  margin-right: auto
  padding-bottom: 20px
  color: $darkblue
  font-family: $basic
  font-size: 2.75em
  font-weight: 300
  line-height: 1.5
  text-align: center
  text-transform: uppercase
  &.lite
    color: $white
  &.blue
    color: $blue
  &.case
    font-size: 2.15em
  &__invert
    color: $white
.subtitle
  padding: 4vh 0 0
  color: $blue
  font-family: $basic
  font-size: 1.15em
  font-weight: 600
  line-height: 1.5
  &.dark
    color: $darkblue
  &.lite
    color: $white
  &.blue
    color: $blue
  &.box
    margin: -8px 0 0 -8px
    padding-top: 10px
    font-size: 1.5em
.text
  margin-bottom: 4vh
  padding: 2vh 0
  color: $darkblue
  font-family: $basic
  font-size: 1em
  font-weight: 400
  line-height: 1.75
  &.icon
    font-size: 1em
    line-height: 1.5
    margin: 0
    padding: 0
  &.dark
    color: $darkblue !important
  &.lite
    color: $white !important
  &.blue
    color: $blue !important

.bg-darkblue
  background-color: $darkblue !important
.bg-lite
  background-color: $lite !important
.bg-blue
  background-color: $blue !important
.bg-white
  background-color: $white !important
.bg-lite
  background-color: $lite !important
.bg-yellow
  background-color: $yellow !important

.br-dark
  border-color: $darkblue !important
.br-lite
  border-color: $white !important
.br-blue
  border-color: $blue !important
.br-white
  border-color: $white !important
.br-lite
  border-color: $lite !important
.br-yellow
  border-color: $yellow !important

.br-one
  border-width: 1px
.br-two
  border-width: 2px

.c-dark
  color: $darkblue !important
.c-lite
  color: $white !important
.c-blue
  color: $blue !important
.c-white
  color: $white !important
.c-lite
  color: $lite !important
.c-yellow
  color: $yellow !important

.f-100
  font-weight: 100 !important
.f-200
  font-weight: 200 !important
.f-300
  font-weight: 300 !important
.f-400
  font-weight: 400 !important
.f-500
  font-weight: 500 !important
.f-600
  font-weight: 600 !important
.f-700
  font-weight: 700 !important
.f-800
  font-weight: 800 !important
.f-900
  font-weight: 900 !important
