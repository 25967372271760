.accordion-wrapper {
  width: 100%;
  max-width: 1200px;
  height: 144px;
  border: 3px solid #165aaa;
  margin: 0 auto;
  border-radius: 16px;
  overflow: hidden;
}

.accordion {
  display: flex;
  flex-shrink: 0;
  height: 100%;
  color: black;
  transition: all 0.5s ease-in-out;

  &__item {
    transition: min-width 0.5s ease-in-out;
    cursor: pointer;
    padding: 25px 20px;
    display: flex;
    align-items: center;
    overflow: hidden;
    gap: 24px;

    &-title__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 24px;
    }

    &-title {
      font-weight: 600;
      font-size: 20px;
      color: #eac131;
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      svg {
        width: 64px;
        height: 64px;
      }
    }
    &-text {
      font-weight: 400;
      font-size: 18px;
      color: #ffffff;
    }
  }
}

.blocks-wrapper {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  border: 3px solid #165aaa;
  border-radius: 16px;
  overflow: hidden;
}

.blocks__item {
  padding: 50px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  border-bottom: 3px solid #165aaa;

  &:last-child {
    border-bottom: none;
  }

  &-title {
    font-weight: 600;
    font-size: 20px;
    color: #eac131;
    text-transform: uppercase;
  }

  &-text {
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
  }
}
