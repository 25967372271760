.text-rows-1 {
  text-align: left;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.text-rows-1:hover {
  text-align: left;
  overflow: auto;
  display: block;
}

.text-rows-3 {
  text-align: left;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.text-rows-3:hover {
  text-align: left;
  overflow: auto;
  display: block;
}
