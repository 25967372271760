.faq {
  &__section {
    padding: 120px 0 160px 0;
  }

  &__title {
    padding: 0 25px;
  }

  &__subtitle {
    margin-top: 72px;
    font-weight: 600;
    font-size: 32px;
    color: #252a38;
    text-align: center;
  }

  &__question-container {
    display: flex;
    gap: 20px;

    border-left: none;
    border-right: none;
    padding: 32px 0;
    overflow: hidden;

    border-bottom: 1px solid #252a38;
    &:last-child {
      border-bottom: none;
    }
    &:first-child {
      border-top: 1px solid #252a38;
    }

    h3 {
      color: #252a38;
      font-weight: 600;
      font-size: 24px;
      letter-spacing: 0.02em;
    }

    @media screen and (max-width: 425px) {
      & {
        h3 {
          font-size: 20px;
        }
      }
    }
  }

  &__dropdown-svg {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__question-answer {
    padding: 24px 0;
    display: none;

    &--active {
      display: block;
    }

    p {
      user-select: none;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 0.02em;
    }
  }
}
