.feedback-section__title {
  text-align: left;
}

.feedback-table {
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

@media screen and (max-width: 1024px) {
  .feedback-section__title {
    text-align: center;
  }
}
