.accordion-wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 30px auto;
  overflow: hidden;
  cursor: pointer;
  border-radius: 16px;
}

.accordion {
  display: flex;
  transition: all 0.5s ease-in-out;

  &__item {
    transition: all 0.5s ease-in-out;

    &-content {
      height: 200px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 24px;
      gap: 30px;
    }

    &-title__wrapper {
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-title {
      transform: rotate(-90deg);
      position: absolute;
      white-space: nowrap;
      font-weight: 600;
      font-size: 24px;
      color: #eac131;
    }
    &-text {
      font-weight: 600;
      font-size: 20px;
      color: #fff;
    }
  }
}

.slow-appear-animation {
  animation: slow-appear 0.8s ease-in-out;
}

@keyframes slow-appear {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blocks {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  border-radius: 16px;
  overflow: hidden;

  &__item {
    display: flex;
    min-height: 120px;

    &-title {
      font-weight: 600;
      font-size: 24px;
      color: #eac131;
      white-space: nowrap;
    }

    &-text {
      font-weight: 600;
      font-size: 18px;
      color: #fff;
    }

    &-content {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 25px 30px;
      gap: 40px;

      @media screen and (max-width: 425px) {
        & {
          flex-direction: column;
          text-align: center;
        }
      }
    }
  }
}
