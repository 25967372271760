.block-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.block-title__wrapper {
  padding: 0 25px;

  h1 {
    font-weight: 300;
    font-size: 40px;
    color: #e0e0e0;
    text-align: center;
  }

  @media screen and (max-width: 425px) {
    & {
      padding: 0 15px;
    }
  }
}

.qualifying-accordion__wrapper {
  padding: 0 25px;
  margin-top: 64px;

  @media screen and (max-width: 425px) {
    & {
      padding: 0 15px;
    }
  }
}
