.arrow-pagination {
  padding: 6px 16px;
  color: #165aaa;
  border: 2px solid #165aaa;
  background-color: transparent;
  font-weight: 600;
  font-size: 14px;
  border-radius: 8px;

  transition: all 0.35s ease-in-out;
}

.arrow-pagination:hover {
  background-color: #165aaa;
  color: #fff;
}

.read-feedback {
  padding: 2px 16px;
  color: #165aaa;
  border: 2px solid #165aaa;
  background-color: transparent;
  font-weight: 600;
  font-size: 12px;
  border-radius: 8px;

  transition: all 0.35s ease-in-out;
}

.read-feedback:hover {
  background-color: #165aaa;
  color: #fff;
}
