/* Base */

html
  font-family: $basic
  font-weight: 300
  font-size: 1rem
  font-smooth: always
  -webkit-font-smoothing: antialised
body
  padding: 0
  font-size: 95%
  font-family: $basic
  font-weight: 300
  background: $white
  background-size: 100%
  max-width: 100%
  overflow-x: hidden
  &.fix-mob
    +v-sm
      overflow: hidden
  a
    text-decoration: none
    cursor: pointer !important
  input,
  button
    border: none
    outline: none

#intro
  width: 100%
  display: flex
  flex-direction: column
  justify-content: center
  background-color: $darkblue
  border-radius: 0 0 0 240px
  .pic-intro
    zoom: .95
    +v-sm
      margin-top: -80px !important
      margin-bottom: 25px
      zoom: .75
  .left-shift
    margin-left: 40px
    +v-sm
      margin-left: 25px



.form
  padding: 64px 64px 80px
  border-radius: 24px
  background-color: $darkblue
  +v-sm
    margin-top: 40px

.container
  width: 90% !important
  margin-left: 5% !important
  margin-right: 5% !important
  max-width: 1200px !important
  margin-left: auto !important
  margin-right: auto !important

.sp-form

.sp-form-outer
  position: relative
  max-width: 100%
  top: -450px

.form-container
  +v-xs
    min-height: 30vh

.sp-form
  position: absolute
  right: 20%
  margin: 0 !important
  max-width: fit-content !important

  +v-md
    right: 120px
    max-width: 350px !important
  +v-xs

.sp-form-outer
  width: 100%
  height: 0
  left: 20px
