.section {
  width: 100%;
  margin: 0 auto;
  padding: 120px 0 220px 0;
  background: #165aaa;

  &__title-wrapper {
    padding: 0 25px;

    h1 {
      font-weight: 300;
      font-size: 56px;
      color: #fff;
      text-align: center;
    }

    @media screen and (max-width: 425px) {
      & {
        padding: 0 15px;

        h1 {
          font-size: 48px;
        }
      }
    }
  }

  &__inner-content {
    max-width: 1200px;
    margin: 0 auto;
  }
}

.growthLevels-accordion__wrapper {
  padding: 0 25px;
  margin-top: 88px;
  @media screen and (max-width: 425px) {
    & {
      padding: 0 15px;
    }
  }
}
