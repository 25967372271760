.features__section {
  padding: 120px 0 200px 0;
  background-color: #252a38;
}

.internship-pros {
  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__title-wrapper {
    padding: 0 25px;

    h1 {
      font-weight: 300;
      font-size: 56px;
      color: #eac131;
      text-align: center;
    }
  }

  &__list {
    display: flex;
    gap: 40px;
    justify-content: center;
    margin-top: 107px;
    padding: 0 25px;
    flex-wrap: wrap;

    &-item {
      max-width: 360px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        font-weight: 600;
        font-size: 24px;
        color: #eac131;
        margin-top: 27.33px;
        text-align: center;
      }

      p {
        font-weight: 400;
        font-size: 18px;
        color: #fff;
        margin-top: 24px;
        text-align: center;
      }
    }
  }
}

.achievements {
  &__wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 120px;
  }
  &__title-wrapper {
    padding: 0 25px;

    h1 {
      font-weight: 300;
      font-size: 56px;
      color: #fff;
      text-align: center;
    }
  }

  &__list {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-top: 107px;
    padding: 0 25px;
    flex-wrap: wrap;

    &-item {
      max-width: 360px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: center;
      border-top: 8px solid #eac131;
      border-radius: 16px;
      background: #165aaa;
      padding: 40px 0;

      h1 {
        font-weight: 500;
        font-size: 64px;
        color: #fff;
      }

      h3 {
        font-weight: 500;
        font-size: 24px;
        color: #eac131;
        text-align: center;
      }
      h4 {
        font-weight: 600;
        font-size: 20px;
        color: #fff;
        text-align: center;
      }
    }
  }
}
