@mixin square_style {
  width: 400px;
  height: 400px;
  background: #eac131;
  border-radius: 30px;
}

@mixin alingh_justyf {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.module-text__block {
  &-top {
    position: relative;
    z-index: 10;
    min-height: 200px;
    max-width: 500px;
    text-align: left;
    font-weight: 600;
    font-size: 24px;
    color: #fff;
    left: 0;
    top: 258px;
  }
  &-bottom {
    position: relative;
    z-index: 10;
    min-height: 200px;
    max-width: 500px;
    text-align: left;
    font-weight: 600;
    font-size: 24px;
    color: #fff;
    left: 0;
    bottom: 458px;
  }
}

.module_container {
  display: flex;
  align-items: center;
  overflow: hidden;

  text-align: center;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  height: 3200px;

  position: relative;

  .rhombus {
    position: absolute;
    right: -850px;
    top: 500px;
    padding: 260px 260px 80px 50px;

    width: 2192px;
    height: 2192px;
    background-color: #252a38;
    border-radius: 240px;
    transform: rotate(45deg);

    display: grid;
    grid-template-columns: 0.3fr 1fr 0.3fr 1fr 0.3fr 1fr;
    grid-template-rows: 1fr 0.5fr 1fr 0.5fr 1fr 0.5fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    .div1 {
      grid-area: 2 / 1 / 3 / 2;
    }
    .div2 {
      grid-area: 4 / 1 / 5 / 2;
    }
    .div3 {
      grid-area: 1 / 2 / 2 / 3;
      @include square_style;
      @include alingh_justyf;
    }
    .div4 {
      grid-area: 3 / 2 / 4 / 3;
      @include square_style;
      @include alingh_justyf;
    }
    .div5 {
      grid-area: 5 / 2 / 6 / 3;
      @include square_style;
      @include alingh_justyf;
    }
    .div6 {
      grid-area: 2 / 3 / 3 / 4;
    }
    .div7 {
      grid-area: 4 / 3 / 5 / 4;
    }
    .div8 {
      grid-area: 6 / 3 / 7 / 4;
    }
    .div9 {
      grid-area: 3 / 4 / 4 / 5;
      @include square_style;
      @include alingh_justyf;
    }
    .div10 {
      grid-area: 5 / 4 / 6 / 5;
      @include square_style;
      @include alingh_justyf;
    }
    .div11 {
      grid-area: 4 / 5 / 5 / 6;
    }
    .div12 {
      grid-area: 6 / 5 / 7 / 6;
    }
    .div13 {
      grid-area: 5 / 6 / 6 / 7;
      @include square_style;
      @include alingh_justyf;
    }

    .sqare_title {
      color: #252a38;
      font-family: "Inter Tight";
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
      letter-spacing: 0.02em;
      transform: rotate(-45deg);
      align-self: flex-start;
      margin-left: 20px;
      margin-top: 90px;
    }

    .square_img {
      font-size: 160px;
      line-height: 160px;
      text-shadow: 0px 11.2723px 28.1808px rgba(56, 45, 4, 0.5);
      transform: rotate(-45deg);
      // width: 264px;
      // height: 264px;
      // display: flex;
      // align-items: center;
      // text-align: center;
      // letter-spacing: 0.02em;
    }

    .sqare_number {
      color: #165aaa;
      font-family: "Inter Tight";
      font-style: normal;
      font-weight: 700;
      font-size: 64px;
      line-height: 90px;
      transform: rotate(-45deg);
      align-self: flex-end;
      margin-right: 50px;
      margin-bottom: 60px;
    }

    .outer-circle {
      background-color: #252a38;
      width: 240px;
      height: 240px;
      border-radius: 50%;
      border: 4px solid #165aaa40;
      animation: pulse 2s infinite;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .middle-circle {
        background-color: #252a38;
        width: 192px;
        height: 192px;
        border-radius: 50%;
        border: 6px solid #165aaa80;
        animation: pulse 2s infinite reverse;
        display: flex;
        justify-content: center;
        align-items: center;

        @keyframes pulse {
          0% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.05);
          }
          100% {
            transform: scale(1);
          }
        }

        .inner-circle {
          width: 144px;
          height: 144px;
          border-radius: 50%;
          background-color: #165aaa;
          display: flex;
          justify-content: center;
          align-items: center;

          .number-circle {
            width: 65px;
            height: 65px;
            border-radius: 50%;
            background-color: #252a38;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: rotate(-45deg);

            .number {
              color: #eac131;
              font-family: "Inter Tight";
              font-style: normal;
              font-weight: 600;
              font-size: 40px;
              line-height: 40px;
              user-select: none;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .module_container {
    height: 2880px;
    .rhombus {
      scale: 0.9;
      right: -890px;
      top: 360px;
    }
  }
}

@media (max-width: 1500px) {
  .module_container {
    height: 2750px;
    .rhombus {
      scale: 0.8;
      right: -900px;
      top: 200px;
    }
  }
}

@media (max-width: 1300px) {
  .module_container {
    height: 2500px;
    .rhombus {
      scale: 0.7;
      right: -930px;
      top: 50px;
    }
  }
}

@media (max-width: 1100px) {
  .module-text__block {
    &-top {
      top: 150px;
    }
  }
  .module_container {
    height: 2300px;
    .rhombus {
      scale: 0.6;
      right: -950px;
      top: -100px;
    }
  }
}

@media (max-width: 900px) {
  .module_container {
    height: 2300px;
    .rhombus {
      scale: 0.5;
      right: -980px;
      top: -30px;
    }
  }
}

@media (max-width: 750px) {
  .module_container {
    height: 2050px;
    .rhombus {
      scale: 0.4;
      right: -1000px;
      top: -150px;
    }
  }
}

@media (max-width: 550px) {
  .module-text__block {
    &-top {
      top: 40px;
    }
  }
  .module_container {
    height: 1700px;
    .rhombus {
      scale: 0.3;
      right: -1025px;
      top: -350px;
    }
  }
}

@media (max-width: 425px) {
  .module_container {
    //height: 1100px;
    .rhombus {
      scale: 0.275;
      right: -1035px;
    }
  }
}

// @media (max-width: 425px) {
//   .module_container {
//     position: relative;

//     .rhombus {
//       position: absolute;
//       scale: 0.3;
//       // top: -500px;
//       // left: -450px;
//     }
//   }
// }
