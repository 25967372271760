.accordion-wrapper {
  width: 100%;
  max-width: 1200px;
  height: 128px;
  border: 3px solid #165aaa;
  margin: 30px auto;
  border-radius: 16px;
  overflow: hidden;
}

.accordion {
  display: flex;
  flex-shrink: 0;
  height: 100%;
  color: black;
  transition: all 0.5s ease-in-out;

  &__item {
    transition: min-width 0.5s ease-in-out;
    cursor: pointer;
    padding: 24px;
    display: flex;
    align-items: center;
    gap: 15px;

    &-content {
      overflow: hidden;
      font-weight: 400;
      font-size: 18px;
    }

    p {
      margin: 0 !important;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.pre-wrap {
  white-space: pre-wrap !important;
}

.slow-appear-animation {
  animation: slow-appear 0.8s ease-in-out;
}

@keyframes slow-appear {
  0% {
    opacity: 0;
    white-space: nowrap;
  }
  50% {
    opacity: 0;
    white-space: pre-wrap;
  }
  100% {
    opacity: 1;
    white-space: pre-wrap;
  }
}

.blocks-wrapper {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  flex-direction: column;
  gap: 10px;

  border-radius: 16px;

  p {
    margin: 0 !important;
  }

  @media screen and (max-width: 768px) {
    & {
      max-width: 350px;
      grid-template-columns: repeat(1, 1fr);
      text-align: center;

      .blocks__item {
        padding: 40px;
        gap: 40px;
        align-items: center;
        justify-content: center;

        svg {
          width: 90px;
          height: 90px;
        }
      }
    }
  }
}

.blocks__item {
  padding: 40px 20px;
  border: 3px solid #165aaa;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  svg {
    width: 75px;
    height: 75px;
    margin: 0 auto;
  }

  &-content {
    font-weight: 400;
    font-size: 18px;
  }
}
