.accordion-wrapper {
  width: 100%;
  max-width: 1000px;
  height: 89px;
  border: 3px solid #fff;
  margin: 30px auto;
  border-radius: 16px;
  overflow: hidden;
}

.accordion {
  display: flex;
  color: black;
  height: 100%;
  transition: all 0.5s ease-in-out;

  &__item-wrapper {
    transition: min-width 0.5s ease-in-out;
    cursor: pointer;
    padding: 25px 20px;
    display: flex;
    align-items: center;
    gap: 30px;
  }

  &__item {
    padding: 0 10px;
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 32px;

    &-content {
      display: flex;
      flex-direction: column;

      h1 {
        white-space: nowrap;
        font-weight: 700;
        font-size: 24px;
        color: #eac131;
      }
      h2 {
        font-weight: 400;
        font-size: 24px;
        color: #e0e0e0;
      }
    }
  }
}

.slow-appear-animation {
  animation: slow-appear 0.5s ease-in-out;
}

@keyframes slow-appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.blocks-wrapper {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  border: 3px solid #fff;
  border-radius: 16px;
  overflow: hidden;
}

.blocks__item {
  padding: 25px 20px;
  display: flex;
  align-items: center;
  gap: 32px;

  border-bottom: 3px solid #fff;

  &:last-child {
    border-bottom: none;
  }

  h1 {
    white-space: nowrap;
    font-weight: 700;
    font-size: 24px;
    color: #eac131;
  }
  h2 {
    font-weight: 400;
    font-size: 24px;
    color: #e0e0e0;
  }

  @media screen and (max-width: 425px) {
    & {
      svg {
        width: 90px;
        height: 90px;
      }

      padding: 40px;

      flex-direction: column;
      text-align: center;
    }
  }
}
