
.form-control
  font-weight: 400 !important
  height: 54px !important
.btn
  font-size: .8em !important
  font-weight: 600 !important
  background-color: $yellow !important
  &:hover
    background-color: $white !important
    cursor: pointer !important
  &--small
    padding: 13px 32px 12px !important
    font-size: .8em !important
    text-transform: uppercase
    &:hover
      color: $white !important
      background-color: $blue !important
  &--blue
    color: $white
    padding: 13px 32px 12px !important
    font-size: .8em !important
    text-transform: uppercase
    transform: translateY(-2px)
    background-color: $blue !important
    &:hover
      color: $darkblue !important
      background-color: $yellow !important
