.header {
  justify-content: flex-start;
  border-radius: 0 0 240px 0;

  &--mainPage {
    justify-content: flex-start;
    border-radius: 0 0 240px 0 !important;
    width: 90% !important;
    margin: 0 auto;
  }
}

.to-footer__btn {
  margin-top: 64px;
  margin-bottom: 50px;
  display: flex;
  width: 100%;
  justify-content: center;
}

.navbar-wrapper {
  width: 100%;
  display: flex;

  justify-content: space-between;
  gap: 60px;
}

.burger-menu {
  display: none;
  background-color: transparent;
}

.navbar {
  &__list {
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 5px;

    padding: 0;
    margin: 0;

    &-item {
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: center;
      color: #fff;
    }
  }
}

.logo-wrapper {
  img {
    min-width: 75px;
    min-height: 75px;
    width: 75px;
  }

  &--mainPage {
    min-width: 235px;
    position: relative;

    img {
      min-width: 235px;
      min-height: 235px;
      position: absolute;
    }

    @media screen and (max-width: 830px) {
      & {
        min-width: 0px;
        img {
          min-width: 75px;
          min-height: 75px;
          max-width: 75px;
          max-height: 75px;
          width: fit-content;
          position: relative;
        }
      }
    }
  }
}

// .logo-wrapper {
//   min-width: 235px;
//   position: relative;

//   img {
//     min-width: 235px;
//     min-height: 235px;
//     position: absolute;
//   }

//   @media screen and (max-width: 830px) {
//     & {
//       min-width: 0px;
//       img {
//         min-width: 75px;
//         min-height: 75px;
//         max-width: 75px;
//         max-height: 75px;
//         width: fit-content;
//         position: relative;
//       }
//     }
//   }
// }

.header-content {
  display: flex;
  column-gap: 22px;
  margin: 48px 0 0 0;
  min-height: 500px;

  &__left {
    display: flex;
    flex-direction: column;
    margin-top: 114px;
    max-width: 620px;

    &--general {
      h3 {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0em;
        text-align: left;
        color: #fff;
      }
      h1 {
        font-size: 80px;
        font-weight: 300;
        letter-spacing: 0em;
        text-align: left;
        color: #eac131;
        margin-top: 4px;
      }
      p {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 12px;
        color: #fff;
      }
    }

    &--mainPage {
      margin-top: 294px;

      h1 {
        font-size: 42px;
        color: #fff;
        font-weight: 700;
        margin-top: 24px;
      }
      h3 {
        color: #eac131;
        font-size: 32px;
      }

      @media screen and (max-width: 600px) {
        h1 {
          font-size: 36px;
        }
        h3 {
          font-size: 24px;
        }
      }
    }
  }
  &__right {
    width: 100%;
    display: flex;
    height: fit-content;
    justify-content: flex-end;
    align-items: flex-start;
    min-width: 400px;

    img {
      width: 100%;
      max-width: 500px;
    }
  }

  @media screen and (max-width: 1024px) {
    & {
      flex-direction: column;
      align-items: center;
      row-gap: 40px;

      &__right {
        justify-content: center;
        min-width: 0;
        img {
          max-width: 500px;
        }
      }
    }
  }
}

.hamburger {
  transform: scale(0.7);
}

.hamburger .line {
  width: 50px;
  height: 5px;
  background-color: #eac131;
  display: block;
  margin: 8px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#hamburger-1.is-active .line:nth-child(2) {
  opacity: 0;
}

#hamburger-1.is-active .line:nth-child(1) {
  -webkit-transform: translateY(13px) rotate(45deg);
  -ms-transform: translateY(13px) rotate(45deg);
  -o-transform: translateY(13px) rotate(45deg);
  transform: translateY(13px) rotate(45deg);
}

#hamburger-1.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-13px) rotate(-45deg);
  -ms-transform: translateY(-13px) rotate(-45deg);
  -o-transform: translateY(-13px) rotate(-45deg);
  transform: translateY(-13px) rotate(-45deg);
}

#hamburger-2.is-active .line:nth-child(1) {
  -webkit-transform: translateY(13px);
  -ms-transform: translateY(13px);
  -o-transform: translateY(13px);
  transform: translateY(13px);
}

#hamburger-2.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-13px);
  -ms-transform: translateY(-13px);
  -o-transform: translateY(-13px);
  transform: translateY(-13px);
}

@media screen and (max-width: 830px) {
  .navbar {
    display: none;
  }

  .to-footer__btn {
    margin-top: 40px;
    margin-bottom: 81px;
    img {
      width: 43px;
    }
  }

  .burger-menu {
    display: block;
  }
  .header-content {
    margin-top: 57px;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;

    &__left {
      margin-top: 0;

      &--general {
        h3 {
          font-size: 20px;
        }
        h1 {
          font-size: 36px;
          margin-top: 12px;
        }
        p {
          font-size: 18px;
        }
      }
    }
  }
}
