.block-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.block-title__wrapper {
  padding: 0 25px;

  h1 {
    font-weight: 300;
    font-size: 56px;
    color: #165aaa;
    text-align: center;
  }

  @media screen and (max-width: 425px) {
    & {
      padding: 0 15px;
      h1 {
        font-size: 48px;
      }
    }
  }
}

.mentors-accordion__wrapper {
  padding: 0 25px;
  margin-top: 64px;

  @media screen and (max-width: 425px) {
    & {
      padding: 0 15px;
    }
  }
}
