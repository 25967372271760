.accordion {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  gap: 16px;
  height: 165px;

  &__item {
    cursor: pointer;
    height: 100%;
    transition: all 0.5s ease-in-out;
    border-right: 8px solid #eac131;
    border-radius: 16px;
    background: #165aaa;
    padding: 24px 40px 24px 48px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;

    &-title__wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-title {
      position: absolute;
      white-space: nowrap;
      font-weight: 600;
      font-size: 24px;
      color: #fff;

      transform: rotate(0);
    }
    &-text {
      font-weight: 600;
      font-size: 18px;
      color: #fff;
    }

    &:hover {
      .accordion__item-title {
        color: #eac131;
      }
    }
  }
}

.slow-appear-animation {
  animation: slow-appear 0.6s ease-in-out;
}

@keyframes slow-appear {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blocks {
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 0 auto;

  &__item {
    border-radius: 16px;
    background-color: #165aaa;
    border-bottom: 8px solid #eac131;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px 30px;
    text-align: center;
    gap: 40px;

    h1 {
      font-weight: 600;
      font-size: 24px;
      color: #eac131;
    }

    p {
      margin: 0;
      font-weight: 600;
      font-size: 18px;
      color: #fff;
    }
  }
}
