#intro
  .t-pages
    font-size: 4.2em
    padding: 0
    margin: -20px 0 -10px -4px
  

#footer
  &.pages
    min-height: auto
    margin-bottom: -360px
    padding-bottom: 0 !important
    background-color: $darkblue

.sp-form,
.sp-form-outer
  

.dropdown-toggle
  &::after
    transform: translateY(3px)

.dropdown-menu
  background-color: $yellow !important
  .dropdown-item
    font-size: .92em
    font-weight: 500

.p-title
  padding: 120px 0 104px
  color: $blue
  font-size: 3em
  font-weight: 300
  text-align: center
  +v-sm
    padding: 40px 0 40px
    font-weight: 1.75em

.intership
  section
    padding-bottom: 120px
    +v-sm
      padding-bottom: 40px

#sidenav
  position: fixed
  top: 30vh
  right: 16px
  text-align: center
  z-index: 999999
  a
    display: flex
    justify-content: center
    align-items: center
    width: 20px
    height: 20px

    margin-bottom: 10px
    background-color: $white
    border-radius: 50%
    &.active
      position: relative
      border: 1px solid $blue
      span
        background-color: $blue
    span
      display: block
      width: 10px
      height: 10px
      border-radius: 50%
      background-color: $yellow

.slick-arrow
  &.slick-prev,
  &.slick-next
    padding-top: 1px
    border-radius: 50px

.slick-dots
  transform: translateY(10px)
  li
    button
      &::before
        color: darken($lite, 80%) !important
    &.slick-active
      button
        &::before
          color: $yellow !important

#faq
  .quest,
  .answ
    width: 80%
    margin: 0 auto
    padding: 0 !important
    height: 60px
    align-items: center
    border-top: 1px solid $darkblue
    h3
      margin: 0 0 0 -10px
      padding: 0
      font-size: 1em !important
  .answ
    padding: 40px 0 !important
    height: auto

#howwork
  padding-bottom: 0 !important
  .card-left,
  .card-right
    position: relative
    width: 320px
    height: 246px
    background-color: $white
    border-radius: 16px
    border-bottom: 10px solid $yellow
    z-index: 100 !important
    .label
      display: flex
      justify-content: center
      align-items: center
      width: 160px
      height: 88px
      background-color: $yellow
  .card-left
    position: relative
    margin: 116px auto 160px
    padding: 2em 2em 1.5em 3em
    &::after
      position: absolute
      top: 80%
      right: 20px
      content: ''
      width: 24px
      height: 24px
      border-radius: 12px
      border-bottom: 6px solid $yellow
      background-color: $blue
      z-index: 1
    &::before
      position: absolute
      width: 8px
      height: 160px
      top: 40px
      right: 28px
      content: ''
      border-left: 8px dashed $yellow
      z-index: 2
    img
      margin-left: -10px
    p
      padding-right: 20px
      line-height: 1.5
    .label
      position: relative
      margin: -76px -80px 0 170px
      padding-right: 10px
      padding-bottom: 4px
      font-weight: 500
      border-radius: 4px 4px 64px 4px
      z-index: 1
      &::after
        position: absolute
        display: block
        width: 17.5vw
        height: 8px
        top: 40px
        left: 80%
        content: ''
        border-top: 8px dashed $yellow
        z-index: 0
        +v-lg
          width: 17vw
        +v-xl
          width: 14.5vw
  .card-right
    position: relative
    margin: -80px auto 234px
    padding: 3em 3em 1.5em 2em
    text-align: right
    &::after
      position: absolute
      top: 80%
      left: 20px
      content: ''
      width: 24px
      height: 24px
      border-radius: 12px
      border-bottom: 6px solid $yellow
      background-color: $blue
      z-index: 1
    &::before
      position: absolute
      width: 8px
      height: 160px
      top: 40px
      left: 28px
      content: ''
      border-left: 8px dashed $yellow
      z-index: 2
    img
      margin-right: -16px
    p
      padding-left: 20px
      line-height: 1.5
    .label
      position: relative
      margin: -90px -60px 0 -90px
      padding-left: 10px
      padding-bottom: 4px
      font-weight: 500
      border-radius: 4px 4px 4px 64px
      z-index: 1
      &::after
        position: absolute
        display: block
        width: 17.5vw
        height: 8px
        top: 40px
        right: 80%
        content: ''
        border-top: 8px dashed $yellow
        z-index: 0
        +v-lg
          width: 17vw
        +v-xl
          width: 14.5vw
      &.first
        &::after
          display: none

#howmust
  ul
    li
      list-style: circle
  .card-form
    width: 90%
    margin: 0 auto
    padding: 2em 3em
    p
      font-size: 0.85em
      line-height: 1.4
    h5
      padding-bottom: 16px
      font-size: 1em
      font-weight: 700
      text-transform: uppercase
    .btn
      width: 100%
      margin: 20px 0
    &.yellow
      border: 2px solid $yellow
      border-bottom: 16px solid $yellow
    &.blue
      border: 2px solid $blue
      border-bottom: 16px solid $blue
#howinc
  .inc
    position: relative
    margin-left: 20px
    margin-right: 20px
    font-size: 20px
    font-weight: 500
    text-transform: uppercase
    padding-bottom: 40px
    border-bottom: 6px solid $yellow
    &.yellow
      &::after
        position: absolute
        bottom: -40px
        left: calc(50% - 40px)
        display: block
        content: ''
        width: 80px
        height: 40px
        background: url('../../assets/img/svg/icons/y-tr.svg') center center no-repeat
    &.blue
      border-bottom: 6px solid $blue
      &::after
        position: absolute
        bottom: -40px
        left: calc(50% - 40px)
        display: block
        content: ''
        width: 80px
        height: 40px
        background: url('../../assets/img/svg/icons/b-tr.svg') center center no-repeat
  .inc-box
    display: flex
    align-items: center
    height: 160px
    margin-bottom: 1px
    &::after
      position: absolute
      display: block
      content: ''
      left: -50px
      top: 0
      width: 0
      height: 0
      border-style: solid
    p
      margin-top: 14px
      font-weight: 600 !important
    &.full
      position: relative
      width: calc(100% - 100px)
      margin-left: 50px
      justify-content: flex-end
      position: relative
      padding: 48px 40px 48px 64px
      text-align: right
      img
        margin-left: 32px
      &::after
        border-width: 80px 50px 80px 0
      &.one
        background-color: rgba(251,232,164,.2)
        &::after
          border-color: transparent rgba(251,232,164,.2) transparent transparent
      &.two
        background-color: rgba(251,232,164,.3)
        &::after
          border-color: transparent rgba(251,232,164,.3) transparent transparent
      &.three
        background-color: rgba(251,232,164,.4)
        &::after
          border-color: transparent rgba(251,232,164,.4) transparent transparent
      &.four
        background-color: rgba(251,232,164,.5)
        &::after
          border-color: transparent rgba(251,232,164,.5) transparent transparent
      &.five
        background-color: rgba(251,232,164,.6)
        &::after
          border-color: transparent rgba(251,232,164,.6) transparent transparent
    &.free
      margin-right: 50px
      position: relative
      justify-content: flex-start
      position: relative
      padding: 48px 64px 48px 40px
      text-align: left
      img
        margin-right: 32px
      &::after
        left: 100%
        border-width: 80px 0 80px 50px
      &.one
        background-color: rgba(219,231,245,.2)
        &::after
          border-color: transparent transparent transparent rgba(219,231,245,.2)
      &.two
        background-color: rgba(219,231,245,.3)
        &::after
          border-color: transparent transparent transparent rgba(219,231,245,.3)
      &.three
        background-color: rgba(219,231,245,.4)
        &::after
          border-color: transparent transparent transparent rgba(219,231,245,.4)
      &.four
        background-color: rgba(219,231,245,.5)
        &::after
          border-color: transparent transparent transparent rgba(219,231,245,.5)
      &.five
        background-color: rgba(219,231,245,.6)
        &::after
          border-color: transparent transparent transparent rgba(219,231,245,.6)
